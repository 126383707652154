import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ILocationState, LocationStore } from '@store/location-store/location.store';
import { IFlatTree, ITree } from '@shared/models/tree';

@Injectable({ providedIn: 'root' })
export class LocationSelector extends Query<ILocationState> {
	constructor(
		protected store: LocationStore
	) {
		super(store);
	}

	public getLocationsTree(): ITree {
		return this.getValue().unitLocations;
	}

	public getLocationList(): IFlatTree[] {
		return this.getValue().unitLocationList;
	}

	public getLocationMap(): Map<number, IFlatTree> {
		return this.getValue().unitLocationMap;
	}

	public getLocationById(id: number): IFlatTree {
		return this.getLocationMap().get(id);
	}

	public getLocationTreeByParentId(id: number): ITree {
		const locationTree = this.getLocationsTree();

		return this.findLocationByParentId(id, locationTree);
	}

	public findLocationByParentId(id: number, location: ITree): ITree {
		if (location.id === id) {
			return location;
		}

		if (!location?.children?.length) {
			return null
		}

		for (let i = 0; i < location.children.length; i++) {
			const result = this.findLocationByParentId(
				id,
				location.children[i]
			);

			if (result !== null) {
				return result;
			}
		}

		return null;
	}
}
